@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background: linear-gradient(
    to right,
    #3f3f3f,
    rgb(15, 15, 15),
    rgb(15, 15, 15),
    rgb(15, 15, 15),
    rgb(15, 15, 15),
    rgb(15, 15, 15),
    rgb(15, 15, 15)
  ); */
  --tw-bg-opacity: 1;
  background-color: rgb(231, 231, 231) !important;
  font-family: Inter var, ui-sans-serif, system-ui, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-feature-settings: "cv02", "cv03", "cv04", "cv11";
  -webkit-font-smoothing: antialiased;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

span,
p,
svg,
ul,
i,
h1,
h2,
h3,
h4,
h5 {
  color: white;
}

label {
  font-style: italic;
  font-weight: 500;
}

hr {
  color: rgb(202, 202, 202);
}

.react-calendar__month-view__days__day {
}

.react-calendar__navigation {
  display: flex;
}

.react-calendar__navigation__arrow {
  padding: 5px;
  font-size: 24px;
}

.react-calendar__tile--active {
  background-color: white;
  color: black;
  border-radius: 10px;
  font-weight: 600 !important;
}

.react-calendar__tile {
  padding: 6px;
  font-weight: 400;
  font-size: 17px;
}
.react-calendar__month-view__weekdays__weekday {
  margin-bottom: 10px;
  margin-top: 10px;
  padding-left: 15px;
  text-transform: uppercase;
  text-decoration: none !important;
  font-style: italic;
  font-weight: 500;
  user-select: none;
}

.react-calendar__navigation__label__labelText {
  text-transform: uppercase;
  font-style: italic;
  font-weight: 600;
  font-size: 18px;
}

abbr {
  text-decoration: none !important;
}

.cardzim {
  overflow: hidden;
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
  font-family: Inter var, ui-sans-serif, system-ui, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-feature-settings: "cv02", "cv03", "cv04", "cv11";
  -webkit-font-smoothing: antialiased;
}
